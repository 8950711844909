export default function ({ $axios, store, app }, inject) {
  $axios.onError((error) => {
    if (error.response.status === 409) {
      store.dispatch('message/messageShow', {
        text: error.response.data.messages[0],
        color: 'error',
      })
    }
    if (error.response.status === 400) {
      store.dispatch('message/messageShow', {
        text: 'پارامترهای ارسالی اشتباه می باشد.',
        color: 'error',
      })
    }
    if (error.response.status === 403) {
      store.dispatch('message/messageShow', {
        text: error.response.data.messages[0],
        color: 'error',
      })
    }
    if (error.response.status == 500) {
      store.dispatch('message/messageShow', {
        text: 'خطای ارتباط با سرور',
        color: 'error',
      })
    }
  })
}
