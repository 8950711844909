import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _38ee48f4 = () => interopDefault(import('../pages/categories.vue' /* webpackChunkName: "pages/categories" */))
const _14085844 = () => interopDefault(import('../pages/comments.vue' /* webpackChunkName: "pages/comments" */))
const _49d36f39 = () => interopDefault(import('../pages/convert.vue' /* webpackChunkName: "pages/convert" */))
const _50160a02 = () => interopDefault(import('../pages/deposits.vue' /* webpackChunkName: "pages/deposits" */))
const _7434f317 = () => interopDefault(import('../pages/lir-price.vue' /* webpackChunkName: "pages/lir-price" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _d4beb662 = () => interopDefault(import('../pages/orders.vue' /* webpackChunkName: "pages/orders" */))
const _cc8d6252 = () => interopDefault(import('../pages/paylir.vue' /* webpackChunkName: "pages/paylir" */))
const _ae1e7ed2 = () => interopDefault(import('../pages/payments.vue' /* webpackChunkName: "pages/payments" */))
const _52e9fe4e = () => interopDefault(import('../pages/posts.vue' /* webpackChunkName: "pages/posts" */))
const _412ee962 = () => interopDefault(import('../pages/price.vue' /* webpackChunkName: "pages/price" */))
const _bdeee326 = () => interopDefault(import('../pages/tickets.vue' /* webpackChunkName: "pages/tickets" */))
const _347094b2 = () => interopDefault(import('../pages/upload-center.vue' /* webpackChunkName: "pages/upload-center" */))
const _c91b56de = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _5e639138 = () => interopDefault(import('../pages/withdrawals.vue' /* webpackChunkName: "pages/withdrawals" */))
const _fb645aae = () => interopDefault(import('../pages/setting/addresses.vue' /* webpackChunkName: "pages/setting/addresses" */))
const _c3040e30 = () => interopDefault(import('../pages/setting/app.vue' /* webpackChunkName: "pages/setting/app" */))
const _72f715be = () => interopDefault(import('../pages/setting/banks.vue' /* webpackChunkName: "pages/setting/banks" */))
const _693ec832 = () => interopDefault(import('../pages/setting/contact.vue' /* webpackChunkName: "pages/setting/contact" */))
const _8e11c136 = () => interopDefault(import('../pages/setting/deposit.vue' /* webpackChunkName: "pages/setting/deposit" */))
const _e51af146 = () => interopDefault(import('../pages/setting/faq.vue' /* webpackChunkName: "pages/setting/faq" */))
const _055a46a2 = () => interopDefault(import('../pages/setting/general.vue' /* webpackChunkName: "pages/setting/general" */))
const _75c99056 = () => interopDefault(import('../pages/setting/order.vue' /* webpackChunkName: "pages/setting/order" */))
const _58efd620 = () => interopDefault(import('../pages/setting/profile.vue' /* webpackChunkName: "pages/setting/profile" */))
const _44a29476 = () => interopDefault(import('../pages/setting/social.vue' /* webpackChunkName: "pages/setting/social" */))
const _8a23b7ba = () => interopDefault(import('../pages/setting/tokens.vue' /* webpackChunkName: "pages/setting/tokens" */))
const _32a27c16 = () => interopDefault(import('../pages/setting/turkish-banks.vue' /* webpackChunkName: "pages/setting/turkish-banks" */))
const _200b7e52 = () => interopDefault(import('../pages/setting/wages.vue' /* webpackChunkName: "pages/setting/wages" */))
const _6cd3fa2a = () => interopDefault(import('../pages/users/blocked.vue' /* webpackChunkName: "pages/users/blocked" */))
const _b53dbf9c = () => interopDefault(import('../pages/users/complete-kyc.vue' /* webpackChunkName: "pages/users/complete-kyc" */))
const _df18e104 = () => interopDefault(import('../pages/users/pending-kyc.vue' /* webpackChunkName: "pages/users/pending-kyc" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _13c1e421 = () => interopDefault(import('../pages/ticket/_id.vue' /* webpackChunkName: "pages/ticket/_id" */))
const _00814260 = () => interopDefault(import('../pages/user/_id.vue' /* webpackChunkName: "pages/user/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/categories",
    component: _38ee48f4,
    name: "categories"
  }, {
    path: "/comments",
    component: _14085844,
    name: "comments"
  }, {
    path: "/convert",
    component: _49d36f39,
    name: "convert"
  }, {
    path: "/deposits",
    component: _50160a02,
    name: "deposits"
  }, {
    path: "/lir-price",
    component: _7434f317,
    name: "lir-price"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/orders",
    component: _d4beb662,
    name: "orders"
  }, {
    path: "/paylir",
    component: _cc8d6252,
    name: "paylir"
  }, {
    path: "/payments",
    component: _ae1e7ed2,
    name: "payments"
  }, {
    path: "/posts",
    component: _52e9fe4e,
    name: "posts"
  }, {
    path: "/price",
    component: _412ee962,
    name: "price"
  }, {
    path: "/tickets",
    component: _bdeee326,
    name: "tickets"
  }, {
    path: "/upload-center",
    component: _347094b2,
    name: "upload-center"
  }, {
    path: "/users",
    component: _c91b56de,
    name: "users"
  }, {
    path: "/withdrawals",
    component: _5e639138,
    name: "withdrawals"
  }, {
    path: "/setting/addresses",
    component: _fb645aae,
    name: "setting-addresses"
  }, {
    path: "/setting/app",
    component: _c3040e30,
    name: "setting-app"
  }, {
    path: "/setting/banks",
    component: _72f715be,
    name: "setting-banks"
  }, {
    path: "/setting/contact",
    component: _693ec832,
    name: "setting-contact"
  }, {
    path: "/setting/deposit",
    component: _8e11c136,
    name: "setting-deposit"
  }, {
    path: "/setting/faq",
    component: _e51af146,
    name: "setting-faq"
  }, {
    path: "/setting/general",
    component: _055a46a2,
    name: "setting-general"
  }, {
    path: "/setting/order",
    component: _75c99056,
    name: "setting-order"
  }, {
    path: "/setting/profile",
    component: _58efd620,
    name: "setting-profile"
  }, {
    path: "/setting/social",
    component: _44a29476,
    name: "setting-social"
  }, {
    path: "/setting/tokens",
    component: _8a23b7ba,
    name: "setting-tokens"
  }, {
    path: "/setting/turkish-banks",
    component: _32a27c16,
    name: "setting-turkish-banks"
  }, {
    path: "/setting/wages",
    component: _200b7e52,
    name: "setting-wages"
  }, {
    path: "/users/blocked",
    component: _6cd3fa2a,
    name: "users-blocked"
  }, {
    path: "/users/complete-kyc",
    component: _b53dbf9c,
    name: "users-complete-kyc"
  }, {
    path: "/users/pending-kyc",
    component: _df18e104,
    name: "users-pending-kyc"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/ticket/:id?",
    component: _13c1e421,
    name: "ticket-id"
  }, {
    path: "/user/:id?",
    component: _00814260,
    name: "user-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
