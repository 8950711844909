export const state = () => ({
  defaultLinks: [
    {
      page: 'home',
      to: '/',
      title: 'داشبورد',
      icon: '/icons/dashboard.svg',
      sort: 1,
      status: true,
    },
    {
      page: 'users',
      to: '/users',
      title: 'کاربران',
      icon: '/icons/users.svg',
      sort: 2,
      status: true,
    },
    {
      page: 'orders',
      to: '/orders',
      title: 'سفارشات',
      icon: '/icons/orders.svg',
      sort: 2,
      status: true,
    },
    {
      title: 'واریز ها',
      arrowIcon: 'mdi-chevron-up',
      'arrowIcon-alt': 'mdi-chevron-down',
      icon: '/icons/payment.svg',
      active: true,
      status: true,
      sort: 3,
      children: [
        {
          title: 'ریال',
          to: '/payments',
          status: true,
        },
        {
          title: 'تتر',
          to: '/deposits',
          status: true,
        },
      ],
    },
    {
      page: 'withdrawals',
      to: '/withdrawals',
      title: 'برداشت ها',
      icon: '/icons/withdrawal.svg',
      sort: 4,
      status: true,
    },
    {
      page: 'tickets',
      to: '/tickets',
      title: 'تیکت ها',
      icon: '/icons/chats.svg',
      sort: 5,
      status: true,
    },
    {
      page: 'comments',
      to: '/comments',
      title: 'نظرات',
      icon: '/icons/comment.svg',
      sort: 6,
      status: true,
    },
    {
      page: 'posts',
      to: '/posts',
      title: 'پست ها',
      icon: '/icons/blog.svg',
      sort: 7,
      status: true,
    },
    {
      page: 'categories',
      to: '/categories',
      title: 'دسته بندی ها',
      icon: '/icons/categories.svg',
      sort: 8,
      status: true,
    },
    {
      page: 'upload-center',
      to: '/upload-center',
      title: 'مرکز بارگذاری فایل',
      icon: '/icons/upload.svg',
      sort: 9,
      status: true,
    },
    {
      page: 'price',
      to: '/price',
      title: 'قیمت تتر',
      icon: '/icons/usdt.svg',
      sort: 10,
      status: true,
    },
    {
      page: 'lirPrice',
      to: '/lir-price',
      title: 'قیمت لیر',
      icon: '/icons/usdt.svg',
      sort: 10,
      status: true,
    },
    {
      page: 'paylir',
      to: '/paylir',
      title: 'پی لیر',
      icon: '/icons/deposit.svg',
      sort: 10,
      status: true,
    },
    {
      title: 'تنظیمات',
      arrowIcon: 'mdi-chevron-up',
      'arrowIcon-alt': 'mdi-chevron-down',
      icon: '/icons/cog.svg',
      active: false,
      status: true,
      sort: 11,
      children: [
        {
          title: 'سوالات متداول',
          to: '/setting/faq',
          status: true,
        },
        {
          title: 'کارمزدها',
          to: '/setting/wages',
          status: true,
        },
        {
          title: 'آدرس ولت ها',
          to: '/setting/addresses',
          status: true,
        },
        {
          title: 'توکن ها',
          to: '/setting/tokens',
          status: true,
        },
        {
          title: 'عمومی',
          to: '/setting/general',
          status: true,
        },
        {
          title: 'تماس',
          to: '/setting/contact',
          status: true,
        },
        {
          title: 'شبکه های اجتماعی',
          to: '/setting/social',
          status: true,
        },
        {
          title: 'سفارش گیری',
          to: '/setting/order',
          status: true,
        },
        {
          title: 'واریز',
          to: '/setting/deposit',
          status: true,
        },
        {
          title: 'بانک ها',
          to: '/setting/banks',
          status: true,
        },
        {
          title: 'بانک های ترکی',
          to: '/setting/turkish-banks',
          status: true,
        },
        {
          title: 'اپلیکیشن',
          to: '/setting/app',
          status: true,
        },
        {
          title: 'پروفایل',
          to: '/setting/profile',
          status: true,
        },
      ],
    },
  ],
  links: [],
})

export const getters = {
  links: (state) => state.links,
  defaultLinks: (state) => state.defaultLinks,
}

export const mutations = {
  SET: (state) => {
    state.links = state.defaultLinks
  },
}

export const actions = {
  create({ commit }) {
    commit('SET')
  },
}
