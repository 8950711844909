export const state = () => ({
  status: false,
  text: '',
  color: 'error',
})

export const getters = {
  status: (state) => state.status,
  text: (state) => state.text,
  color: (state) => state.color,
}

export const mutations = {
  MESSAGE_DISABLE: (state) => {
    state.status = false
  },

  MESSAGE_SHOW: (state, value) => {
    state.status = true
    state.text = value.text
    state.color = value.color
  },
}

export const actions = {
  messageShow({ commit }, value) {
    commit('MESSAGE_SHOW', value)
  },

  messageDisable({ commit }) {
    commit('MESSAGE_DISABLE')
  },
}
