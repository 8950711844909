import { render, staticRenderFns } from "./default.vue?vue&type=template&id=256e1aef"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppBar: require('/app/components/app/Bar.vue').default,AppDrawer: require('/app/components/app/Drawer.vue').default,CoreBreadcrumbs: require('/app/components/core/Breadcrumbs.vue').default,CoreMessage: require('/app/components/core/Message.vue').default})
