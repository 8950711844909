import Vue from 'vue'
import { Bar, Line, Doughnut } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Filler,
  Legend,
  PointElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement
)

Vue.component('bar-chart', {
  extends: Bar,
})

Vue.component('line-chart', {
  extends: Line,
})

Vue.component('doughnut-chart', {
  extends: Doughnut,
})
