import Vue from 'vue'
import Vuetify from 'vuetify'

import colors from 'vuetify/es5/util/colors'
import fa from 'vuetify/es5/locale/fa'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default (ctx) => {
  const vuetify = new Vuetify({
    customVariables: ['~/assets/variables.scss'],
    theme: {
      themes: {
        options: {
          themeCache: {
            get: (key) => localStorage.getItem(key),
            set: (key, value) => localStorage.setItem(key, value),
          },
        },
        dark: {
          primary: '#009E89',
          accent: colors.grey.darken3,
          secondary: colors.amber.darken3,
          info: colors.cyan.lighten1,
          warning: colors.amber.base,
          error: colors.deepOrange.accent4,
          success: colors.green.accent3,
        },
        light: {
          primary: '#009E89',
          accent: colors.purple.base,
        },
      },
    },
    rtl: true,
    lang: {
      locales: { fa },
      current: 'fa',
    },
  })
  ctx.app.vuetify = vuetify
  ctx.$vuetify = vuetify.framework
}
