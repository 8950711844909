
export default {
  name: 'DefaultLayout',
  data() {
    return {}
  },
  beforeMount() {
    if (this.$store.state.app.darkMode)
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
  },
}
