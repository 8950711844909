
export default {
  data: () => ({
    items: [
      {
        text: 'داشبورد',
        disabled: true,
        href: '/',
      },
    ],
  }),

  watch: {
    $route(to, from) {
      console.log(to.name)
      this.items = [
        {
          text: 'داشبورد',
          disabled: false,
          href: '/',
        },
      ]
      switch (to.name) {
        case 'users':
          this.items.push({
            text: 'کاربران',
            disabled: true,
          })
          break
        case 'users-pending-kyc':
          this.items.push(
            {
              text: 'کاربران',
              href: '/users',
              disabled: false,
            },
            {
              text: 'کاربران در انتظار احراز',
              disabled: true,
            }
          )
          break
        case 'users-complete-kyc':
          this.items.push(
            {
              text: 'کاربران',
              href: '/users',
              disabled: false,
            },
            {
              text: 'کاربران احراز شده',
              disabled: true,
            }
          )
          break
        case 'users-blocked':
          this.items.push(
            {
              text: 'کاربران',
              href: '/users',
              disabled: false,
            },
            {
              text: 'کاربران مسدود شده',
              disabled: true,
            }
          )
          break
        case 'user-id':
          this.items.push(
            {
              text: 'کاربران',
              href: '/users',
              disabled: false,
            },
            {
              text: 'کاربر مشخص',
              disabled: true,
            }
          )
          break
        case 'tickets':
          this.items.push({
            text: 'تیکت ها',
            disabled: true,
          })
          break
        case 'orders':
          this.items.push({
            text: 'سفارشات',
            disabled: true,
          })
          break
        case 'convert':
          this.items.push({
            text: 'تبدیل',
            disabled: true,
          })
          break
        case 'payments':
          this.items.push({
            text: 'پرداخت ها',
            disabled: true,
          })
          break
        case 'withdrawals':
          this.items.push({
            text: 'برداشت ها',
            disabled: true,
          })
          break
        case 'price':
          this.items.push({
            text: 'قیمت تتر',
            disabled: true,
          })
          break
        case 'ticket-id':
          this.items.push(
            {
              text: 'تیکت ها',
              href: '/tickets',
              disabled: false,
            },
            {
              text: 'جزییات تیکت',
              disabled: true,
            }
          )
          break
        case 'comments':
          this.items.push({
            text: 'نظرات',
            disabled: true,
          })
          break
        case 'products':
          this.items.push({
            text: 'محصولات',
            disabled: true,
          })
          break
        case 'orders':
          this.items.push({
            text: 'سفارشات',
            disabled: true,
          })
          break
        case 'countries-id':
          this.items.push({
            text: 'کشورها',
            disabled: true,
          })
          break
        case 'amounts-id':
          this.items.push({
            text: 'مبالغ',
            disabled: true,
          })
          break
        case 'factors':
          this.items.push({
            text: 'صورتحساب ها',
            disabled: true,
          })
          break
        case 'services':
          this.items.push({
            text: 'خدمات',
            disabled: true,
          })
          break
        case 'categories':
          this.items.push({
            text: 'دسته بندی ها',
            disabled: true,
          })
          break
        case 'upload-center':
          this.items.push({
            text: 'مرکز بارگذاری فایل',
            disabled: true,
          })
          break
        case 'counselors':
          this.items.push({
            text: 'درخواست های مشاوره',
            disabled: true,
          })
          break
        case 'posts':
          this.items.push({
            text: 'پست ها',
            disabled: true,
          })
          break
        case 'setting-general':
          this.items.push({
            text: 'تنظیمات عمومی',
            disabled: true,
          })
          break
        case 'setting-token':
          this.items.push({
            text: 'تنضیمات توکن',
            disabled: true,
          })
          break
        case 'setting-profile':
          this.items.push({
            text: 'تنظیمات پروفایل',
            disabled: true,
          })
          break
        case 'setting-contact':
          this.items.push({
            text: 'تنظیمات تماس',
            disabled: true,
          })
          break
        case 'setting-addresses':
          this.items.push({
            text: 'تنظیمات آدرس ولت ها',
            disabled: true,
          })
          break
        case 'setting-wages':
          this.items.push({
            text: 'کارمزد ها',
            disabled: true,
          })
          break
        case 'setting-faq':
          this.items.push({
            text: 'سوالات متداول',
            disabled: true,
          })
          break
        case 'setting-order':
          this.items.push({
            text: 'سفارش گیری',
            disabled: true,
          })
          break
        case 'setting-deposit':
          this.items.push({
            text: 'تنظیمات برداشت',
            disabled: true,
          })
          break
        case 'setting-banks':
          this.items.push({
            text: 'تنظیمات بانک ها',
            disabled: true,
          })
          break
        case 'setting-turkish-banks':
          this.items.push({
            text: 'تنظیمات بانک های ترکی',
            disabled: true,
          })
          break
        case 'setting-social':
          this.items.push({
            text: 'تنظیمات شبکه های اجتماعی',
            disabled: true,
          })
          break
        case 'setting-app':
          this.items.push({
            text: 'تنظیمات اپلیکیشن',
            disabled: true,
          })
          break
        default:
          break
      }
    },
  },
}
