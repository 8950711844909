
export default {
  computed: {
    drawer: {
      get () {
        return this.$store.state.app.drawer
      },
      set (value) {
        this.$store.dispatch('app/toggleDrawer', value)
      },
    },
    links () {
      return { ...this.$store.state.drawer.links }
    },
    defaultColor () {
      return this.$store.state.app.defaultColor
    },
    dark () {
      return this.$store.state.app.darkMode
    },
  },
  mounted () {
    this.$store.dispatch('drawer/create')
  },
}
