
export default {
  data: () => ({
    snackTimeout: 3000,
    timeoutQuery: null,
  }),
  computed: {
    error () {
      return this.$store.state.message.status
    },
    text () {
      return this.$store.state.message.text
    },
    color () {
      return this.$store.state.message.color
    },
  },
  watch: {
    error () {
      setTimeout(() => {
        this.errorDisable()
      }, this.snackTimeout)
    },
  },
  methods: {
    errorDisable () {
      this.$store.dispatch('message/messageDisable')
    },
  },
}
