export const actions = {
  async getData({ commit }, value) {
    return await this.$axios.$get(
      `/v1/${value.model}?page=${value.options.page}&limit=${value.options.limit}&query=${value.search}&sort=${value.options.sort}&order=${value.options.order}&id=${value.id}`
    )
  },

  async edit({ commit }, value) {
    return await this.$axios.$put(`/v1/${value.model}`, {
      id: value.id,
      data: value.data,
    })
  },

  async add({ commit }, value) {
    return await this.$axios.$post(`/v1/${value.model}`, value.data)
  },
}
