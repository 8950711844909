
export default {
  data: () => ({
    messages: [],
    message: '',
    snackbar: false,
    darkMode: false,
  }),

  beforeMount() {
    this.darkMode = this.$store.state.app.darkMode
  },

  created() {
    if (process.client) {
      this.messages = JSON.parse(localStorage.getItem('messages') || '[]')
    }
  },

  computed: {
    drawer: {
      set(value) {
        this.$store.dispatch('app/toggleDrawer', value)
      },
      get() {
        return this.$store.state.app.drawer
      },
    },
    defaultColor() {
      return this.$store.state.app.defaultColor
    },
  },

  watch: {
    $route(val) {
      this.title = val.name
    },
    darkMode() {
      return this.$store.state.app.darkMode
    },
  },

  mounted() {
    this.socket = this.$nuxtSocket({
      reconnection: true,
    })
    this.socket.on('receiveMessage', (data) => {
      console.log(data)
      this.snackbar = false
      this.message = data.text
      this.snackbar = true
      this.messages.unshift(data.text)
      if (process.client) {
        localStorage.setItem('messages', JSON.stringify(this.messages))
      }
    })
  },

  methods: {
    async logout() {
      await this.$auth.logout()
      this.$router.push('/login')
    },
    setDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$store.dispatch('app/changeMode')
    },
    removeMessage(i) {
      this.messages.splice(i, 1)
      localStorage.setItem('messages', JSON.stringify(this.messages))
    },
    removeMessages() {
      this.messages = []
      localStorage.setItem('messages', JSON.stringify(this.messages))
    },
    goToConvert() {
      this.$router.push('/convert')
    },
  },
}
