export const IoSocketStatus = () => import('../../node_modules/nuxt-socket-io/lib/components/SocketStatus.js' /* webpackChunkName: "components/io-socket-status" */).then(c => wrapFunctional(c.default || c))
export const AppBar = () => import('../../components/app/Bar.vue' /* webpackChunkName: "components/app-bar" */).then(c => wrapFunctional(c.default || c))
export const AppDrawer = () => import('../../components/app/Drawer.vue' /* webpackChunkName: "components/app-drawer" */).then(c => wrapFunctional(c.default || c))
export const CardAccount = () => import('../../components/card/Account.vue' /* webpackChunkName: "components/card-account" */).then(c => wrapFunctional(c.default || c))
export const CardReport = () => import('../../components/card/Report.vue' /* webpackChunkName: "components/card-report" */).then(c => wrapFunctional(c.default || c))
export const CardWallet = () => import('../../components/card/Wallet.vue' /* webpackChunkName: "components/card-wallet" */).then(c => wrapFunctional(c.default || c))
export const CoreBreadcrumbs = () => import('../../components/core/Breadcrumbs.vue' /* webpackChunkName: "components/core-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const CoreDate = () => import('../../components/core/Date.vue' /* webpackChunkName: "components/core-date" */).then(c => wrapFunctional(c.default || c))
export const CoreImage = () => import('../../components/core/Image.vue' /* webpackChunkName: "components/core-image" */).then(c => wrapFunctional(c.default || c))
export const CoreMessage = () => import('../../components/core/Message.vue' /* webpackChunkName: "components/core-message" */).then(c => wrapFunctional(c.default || c))
export const CoreSwitch = () => import('../../components/core/Switch.vue' /* webpackChunkName: "components/core-switch" */).then(c => wrapFunctional(c.default || c))
export const CoreUploadFiles = () => import('../../components/core/UploadFiles.vue' /* webpackChunkName: "components/core-upload-files" */).then(c => wrapFunctional(c.default || c))
export const CoreUploader = () => import('../../components/core/Uploader.vue' /* webpackChunkName: "components/core-uploader" */).then(c => wrapFunctional(c.default || c))
export const DialogAddPayment = () => import('../../components/dialog/AddPayment.vue' /* webpackChunkName: "components/dialog-add-payment" */).then(c => wrapFunctional(c.default || c))
export const DialogAddWithdrawal = () => import('../../components/dialog/AddWithdrawal.vue' /* webpackChunkName: "components/dialog-add-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const DialogEdit = () => import('../../components/dialog/Edit.vue' /* webpackChunkName: "components/dialog-edit" */).then(c => wrapFunctional(c.default || c))
export const DialogImage = () => import('../../components/dialog/Image.vue' /* webpackChunkName: "components/dialog-image" */).then(c => wrapFunctional(c.default || c))
export const InputButton = () => import('../../components/input/Button.vue' /* webpackChunkName: "components/input-button" */).then(c => wrapFunctional(c.default || c))
export const InputDate = () => import('../../components/input/Date.vue' /* webpackChunkName: "components/input-date" */).then(c => wrapFunctional(c.default || c))
export const InputSearch = () => import('../../components/input/Search.vue' /* webpackChunkName: "components/input-search" */).then(c => wrapFunctional(c.default || c))
export const InputSelect = () => import('../../components/input/Select.vue' /* webpackChunkName: "components/input-select" */).then(c => wrapFunctional(c.default || c))
export const InputTextarea = () => import('../../components/input/Textarea.vue' /* webpackChunkName: "components/input-textarea" */).then(c => wrapFunctional(c.default || c))
export const InputTextfield = () => import('../../components/input/Textfield.vue' /* webpackChunkName: "components/input-textfield" */).then(c => wrapFunctional(c.default || c))
export const TableDefault = () => import('../../components/table/Default.vue' /* webpackChunkName: "components/table-default" */).then(c => wrapFunctional(c.default || c))
export const TableDevice = () => import('../../components/table/Device.vue' /* webpackChunkName: "components/table-device" */).then(c => wrapFunctional(c.default || c))
export const TableEditable = () => import('../../components/table/Editable.vue' /* webpackChunkName: "components/table-editable" */).then(c => wrapFunctional(c.default || c))
export const TableTransaction = () => import('../../components/table/Transaction.vue' /* webpackChunkName: "components/table-transaction" */).then(c => wrapFunctional(c.default || c))
export const TicketMessage = () => import('../../components/ticket/Message.vue' /* webpackChunkName: "components/ticket-message" */).then(c => wrapFunctional(c.default || c))
export const TicketMessages = () => import('../../components/ticket/Messages.vue' /* webpackChunkName: "components/ticket-messages" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
