export const state = () => ({
  drawer: true,
  darkMode: localStorage.getItem('darkMode')
    ? JSON.parse(localStorage.getItem('darkMode'))
    : false,
  defaultColor: 'primary',
  overlay: false,
})

export const getters = {
  drawer: (state) => state.drawer,
  overlay: (state) => state.overlay,
  darkMode: (state) => state.darkMode,
}

export const mutations = {
  SET_DRAWER: (state, value) => {
    state.drawer = value
  },

  SET_MODE: (state) => {
    state.darkMode = !state.darkMode
    localStorage.setItem('darkMode', state.darkMode)
  },

  SET_COLOR: (state) => {
    if (state.darkMode == false) {
      state.defaultColor = 'primary'
    } else {
      state.defaultColor = 'grey darken-4'
    }
  },

  SET_OVERLAY: (state, value) => {
    state.overlay = value
  },
}

export const actions = {
  toggleDrawer({ commit }, value) {
    commit('SET_DRAWER', value)
  },

  changeMode({ commit }) {
    commit('SET_MODE'), commit('SET_COLOR')
  },

  toggleOverlay({ commit }) {
    commit('SET_OVERLAY'), commit('SET_COLOR')
  },
}
